<template>
  <div style="height: 100%; background-color: #fff; padding-top: 12px">
    <!-- <div class="formContentBox"> -->
    <head-layout
      head-title=""
      :head-btn-options="headBtnOptions"
      @head-save="handleSave"
    ></head-layout>
    <div style="padding-top: 10px">
      <avue-form :option="formOptions" ref="addForm" v-model="addForm">
        <template slot="birthday">
          <span v-if="pageType == 'relatedPersonnel'" :style="itemObject(addForm)">
            {{
              addForm.idCoding && addForm.idCoding != ""
                ? getAge(addForm.idCoding)
                : ""
            }}
          </span>
          <span v-if="pageType == 'personnel'">
            {{
              addForm.birthday && addForm.birthday != ""
                ? getAgeByBirthday(addForm.birthday)
                : ""
            }}
          </span>
        </template>
      </avue-form>
    </div>

    <!-- </div> -->
  </div>
</template>

<script>
import HeadLayout from "@/views/components/layout/head-layout";

import { getUser } from "@/api/system/user";
import { getDetail, add } from "@/api/safe/thirduser";

import { getAgeFromIdCard, getBirthDate, calculateAge } from "@/util/util";
import { PATTERN_ID_CARD } from "@/util/validate";
export default {
  components: {
    HeadLayout,
  },
  data() {
    return {
      addForm: {},
      pageType: "",
      personnelFormColum: [],
      opType: "",
      currentId: "",
    };
  },

  computed: {
    formOptions() {
      return {
        size: "small",
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 130,
        column: [
          {
            label: "姓名",
            prop: this.pageType == "personnel" ? "realName" : "userName",
            span: 8,
            disabled: true,
          },
          {
            label: "登录账号",
            prop: this.pageType == "personnel" ? "account" : "phone",
            span: 8,
            disabled: true,
          },
          {
            label: "性别",
            prop: "sexName",
            span: 8,
            display: this.pageType == "personnel",
            disabled: true,
          },
          {
            label: "性别",
            prop: "sex",
            type: "select",
            dicUrl:
                "/api/sinoma-system/dict-biz/dictionary?code=project_sex_type",
              props: {
                label: "dictValue",
                value: "dictKey",
              },
            span: 8,
            dataType: 'number',
            display: this.pageType == "relatedPersonnel",
            disabled: this.opType == "view",
            rules: [
              {
                required: true,
                message: "请选择性别",
                trigger: "blur",
              },
            ],
          },
          {
            label: "年龄",
            prop: "birthday",
            span: 8,
            // display: this.pageType == "personnel",
            disabled: true,
          },
          {
            label: "身份证号",
            prop: "idCoding",
            span: 8,
            display: this.pageType == "relatedPersonnel",
            disabled: this.opType == "view",
            rules: [
              {
                required: true,
                validator: this.validateIdCard,
                trigger: "blur",
              },
            ],
          },
          {
            label: "归属主组织",
            prop: "masterOrganizationName",
            span: 8,
            display: this.pageType == "personnel",
            disabled: true,
          },
          {
            label: "联系电话",
            prop: "phone",
            span: 8,
            display: this.pageType == "personnel",
            disabled: true,
          },
          {
            label: "手机号",
            prop: "phone",
            span: 8,
            display: this.pageType == "relatedPersonnel",
            disabled: this.opType == "view",
            rules: [
              {
                required: true,
                message: "请输入电话号码",
                trigger: "blur",
              },
              { pattern: /^1[3456789]\d{9}$/, message: "电话号码格式错误" },
            ],
          },
          {
            label: "归属其他组织",
            prop: "deptName",
            type: "textarea",
            span: 8,
            display: this.pageType == "personnel",
            disabled: true,
          },
          {
            label: "籍贯",
            prop: "domicile",
            type: "textarea",
            span: 8,
            display: this.pageType == "relatedPersonnel",
            disabled: this.opType == "view",
            rules: [
              {
                required: false,
                message: "请输入籍贯",
                trigger: "blur",
              },
              { max: 50, message: "籍贯不能超过50个字符", trigger: "blur" },
            ],
          },
          {
            label: "工种",
            prop: "post",
            // type: "textarea",
            span: 8,
            display: this.pageType == "relatedPersonnel",
            disabled: this.opType == "view",
            rules: [
              {
                required: true,
                message: "请输入工种",
                trigger: "blur",
              },
              { max: 20, message: "工种不能超过20个字符", trigger: "blur" },
            ],
          },
        ],
      };
    },
    headBtnOptions() {
      let result = [];
      if (["add", "edit"].includes(this.opType)) {
        result.push({
          label: "保存",
          emit: "head-save",
          type: "button",
          btnOptType: "save",
        });
      }
      return result;
    },
    itemObject(item){
      return (item)=>{
        try{
        let age = item.idCoding && item.idCoding != '' ? this.getAge(item.idCoding) :0;
        if(age>=60){
          return {color:'red'};
        }else{
          return {color:'black'};
        }
      }catch{
        return {color:'black'};
      }
      }
    }
  },
  methods: {
    init(id, pageType, opType) {
      // pageType === relatedPersonnel 相关方人员全息过来的  personnel 人员全息过来的
      this.pageType = pageType;
      this.opType = opType;
      this.currentId = id;
      this.getDetails(id);
    },
    getDetails(id) {
      let reqApi = this.pageType == "personnel" ? getUser : getDetail;
      reqApi(id).then((res) => {
        if (res.data.code == 200) {
          let data = res.data.data;

          this.addForm = data;
          // this.addForm.birthday = this.getAgeByBirthday(data.birthday);
          if (this.pageType == "personnel") {
            this.addForm.realName = JSON.parse(data.realName).zh;
            this.addForm.masterOrganizationName = data.roleIds.length
              ? data.roleIds.find((item) => item.isDefaultDept == "1").deptName
              : "";
            this.addForm.deptName = data.roleIds.length
              ? data.roleIds
                  .filter((item) => item.isDefaultDept != "1")
                  .map((item) => item.deptName)
                  .join(",")
              : "";
          }
          // if (this.pageType == "relatedPersonnel") {
          //   this.addForm.idCoding = this.getAgeFromIdCard(data.idCoding);
          // }
        }
      });
    },

    getAge(data) {
      this.addForm.birthday = getBirthDate(data);
      return getAgeFromIdCard(data);
    },
    getAgeByBirthday(data) {
      return calculateAge(data);
    },
    // 身份证校验
    validateIdCard(rule, value, callback) {
      if (value == "") {
        callback(new Error("请填写身份证号码"));
      } else {
        let valid = PATTERN_ID_CARD.test(value);
        if (valid) {
          callback();
        } else {
          callback(new Error("请填写正确的身份证号码"));
        }
      }
    },
    handleSave() {
      this.$refs.addForm.validate((valid, done) => {
        if (valid) {
          let age = this.getAge(this.addForm.idCoding);
          if (age <= 0) {
            this.$message({
              type: "warning",
              message: "年龄不能小于0",
            });
            done();
            return;
          }
          console.log("biaodan", this.addForm);
          add(this.addForm).then((res) => {
            if (res.data.code == 200) {
              this.$message.success(
                this.$t("cip.cmn.msg.success.operateSuccess")
              );
              this.getDetails(this.currentId);
            } else {
              this.$message.success(msg);
            }

            done();
          });
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.formContentBox {
  padding: 12px 0;
  height: calc(100% - 76px);
}
</style>
