<template>
  <div>
    <grid-head-layout
      ref="gridHeadLayout"
      :search-columns="searchColumns"
      @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout
      ref="gridLayOut"
      :table-options="tableOption"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @page-current-change="onLoad"
      @page-size-change="onLoad"
      @page-refresh-change="onLoad"
    >
    </grid-layout>
  </div>
</template>

<script>
import GridLayout from "@/views/components/layout/grid-layout";

import {mapGetters} from "vuex";
import {getEduTree} from "@/api/training/examinationPaper";

import * as API from "@/api/personnelHolography";
export default {
  components: {
    GridLayout,
  },
  data() {
    return {
      tableData: [],
      tableLoading: true,
      isCycle: "",
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      searchColumns: [
        {
          label: "试卷名称",
          prop: "paperName",
          span: 4,
          placeholder: "请输入试卷名称",
        },
      ],
      userId: "",
    };
  },
  computed: {
    ...mapGetters(['userInfo']),
    tableOption() {
      return {
        selection: false,
        // linklabel: "partnerUserName",
        menu: false,
        column: [
          {
            label: '培训任务名称',
            prop: "trainTheme",
            // slot:true,
            align: "left",
            cell: true,
            overHidden: true,
            width: 240,
          },
          {
            label: '试卷名称',
            prop: "paperName",
            // slot:true,
            align: "left",
            cell: true,
            overHidden: true,
            width: 240,
          },
          {
            label: "考试状态",
            prop: "examStatus",
            type: "select",
            width: 140,
            dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=exam_status',
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            align: 'center',
            overHidden: true,
          },
          {
            label: "是否合格",
            prop: "whetherBeQualifiedOrNot",
            type: "select",
            align: 'center',
            span: 4,
            dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=yes_no',
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
          },
          {
            label: "专业分类",
            prop: "subjectType",
            dicData: [],
            type: "tree",
            props: {
              label: "title",
              value: "value",
            },
            dataType: 'number',
            align: 'center',
            overHidden: true,
          },
          {
            label: "答题时长(分钟)",
            prop: "answerTime",
            width: 140,
            align: 'right',
            overHidden: true,
          },
          {
            label: "开始时间",
            prop: "startTime",
            type: "datetime",
            width: 200,
            align: 'center',
            format: "yyyy-MM-dd HH:mm:ss",
            valueFormat: "yyyy-MM-dd HH:mm:ss",
            overHidden: true,
          },
          {
            label: "结束时间",
            prop: "endTime",
            type: "datetime",
            width: 200,
            align: 'center',
            format: "yyyy-MM-dd HH:mm:ss",
            valueFormat: "yyyy-MM-dd HH:mm:ss",
            overHidden: true,
          },
          // {
          //   label: "作业类型",
          //   prop: "examType",
          //   type: "select",
          //   align: 'center',
          //   width: 130,
          //   dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=EXAM_TYPE',
          //   props: {
          //     label: 'dictValue',
          //     value: 'dictKey',
          //   },
          //   overHidden: true,
          // },
          {
            label: "得分",
            prop: "totalScore",
            width: 80,
            align: 'right',
            overHidden: true,
          },
        ]
      };
    },
  },
  methods: {
    init(id) {
      this.userId = id;
      // this.getEduTreeMehod()
      this.onLoad(this.page);
      getEduTree(this.userInfo.tenantId).then(res => {
        const column = this.findObject(this.$refs.gridLayOut.option.column, 'subjectType')
        column.dicData = res.data.data
        // this.findObject(this.$refs.gridHeadLayout.searchOption.column, 'subjectType').dicData = res.data.data
      })
    },
    // 获取列表数据
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;

      params.userId = this.userId;
      API.personnelExamRecord(page.currentPage, page.pageSize, params).then(
        (res) => {
          if (res.data.code == 200) {
            this.tableLoading = false;
            this.tableData = res.data.data.records;
            this.page.total = res.data.data.total;
            this.$refs.gridLayOut.page.total = res.data.data.total;
          }
        }
      );
    },
    // 搜索
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    // 清空
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.$refs.gridHeadLayout.searchForm = {};
      this.onLoad(this.page, searchForm);
    },

  },
};
</script>
<style scoped lang="scss"></style>
