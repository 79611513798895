<template>
  <div>
    <grid-head-layout
      ref="gridHeadLayout"
      :search-columns="searchColumns"
      @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout
      ref="gridLayOut"
      :grid-row-btn="gridRowBtn"
      :table-options="tableOptions"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @page-current-change="handleCurrentChange"
      @page-size-change="handleSizeChange"
      @page-refresh-change="onLoad"
    >
      <!-- <template #meetingName="{ row, index }">
        <el-link type="primary" @click="rowView(row)">{{
          row.meetingName
        }}</el-link>
      </template> -->
    </grid-layout>
  </div>
</template>

<script>
import GridLayout from "@/views/components/layout/grid-layout.vue";

import {
  mettingHeadPage,
  mettingLinePage,
} from "@/api/responsibility/securityMetting";
import { mapGetters } from "vuex";
export default {
  components: {
    GridLayout,
  },
  data() {
    return {
      tableData: [],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      query: {},
      userId: "",
    };
  },
  computed: {
    searchColumns() {
      return [
        {
          prop: "meetingName",
          placeholder: "会议名称",
          span: 4,
        },
      ];
    },
    tableOptions() {
      return {
        menu: false,
        selection: false,
        column: [
          {
            label: "会议议题",
            prop: "meetingName",
            align: "center",
            overHidden: true,
            // slot: true,
          },
          {
            label: "会议类型",
            prop: "meetingType",
            align: "center",
            dataType: 'number',
            dicUrl:
              "/api/sinoma-system/dict-biz/dictionary-tree?code=meeting_type",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
          },
          {
            label: "会议时间",
            prop: "meetingDate",
            align: "center",
            overHidden: true,
          },
        ],
      };
    },
  },
  methods: {
    init(id) {
      this.userId = id;
      this.onLoad(this.page);
    },
    onLoad(page, params = {}) {
      this.tableLoading = true;
      this.page = page;
      (params.userId = this.userId),
      mettingHeadPage(
          page.currentPage,
          page.pageSize,
          Object.assign(params, this.query)
        ).then((res) => {
          const data = res.data.data;
          this.$refs.gridLayOut.page.total = data.total;
          this.tableData = data.records;
          this.tableLoading = false;
        });
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage;
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm);
    },
    //size改变
    handleSizeChange(e) {
      this.page.pageSize = e.pageSize;
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm);
    },
    // 搜索
    gridHeadSearch(query) {
      const data = this.query;
      this.page.currentPage = 1;
      this.query = { ...data, ...query };
      this.onLoad(this.page);
    },
    // 重置
    gridHeadEmpty(query) {
      this.page.currentPage = 1;
      const data = this.query;
      this.query = { ...data, ...query };
      this.onLoad(this.page);
    },
  },
};
</script>
<style scoped lang="scss"></style>
