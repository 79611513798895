<template>
  <div>
    <head-layout
      head-title=""
      :head-btn-options="headBtnOptions"
      @head-upload="handleUpload"
    />
    <grid-head-layout
      ref="gridHeadLayout"
      :search-columns="searchColumns"
      @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"
    >
    </grid-head-layout>
    <grid-layout
      ref="gridLayOut"
      :table-options="tableOptions"
      :grid-row-btn="gridRowBtn"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @page-current-change="handleCurrentChange"
      @page-size-change="handleSizeChange"
      @page-refresh-change="onLoad"
      @row-edit="rowEdit"
      @row-download="rowDownload"
      @row-remove="rowRemove"
    >
      <template #fileName="{ row, index }">
        <el-link type="primary" @click="rowView(row, index)">{{
          getFileName(row.fileName)
        }}</el-link>
      </template>
    </grid-layout>
    <el-dialog title="证件查看" :visible.sync="imageDiavlog">
      <pdf :src="fileUrl.src" v-if="fileUrl.type == 'pdf'"></pdf>
      <el-image :src="fileUrl.src" v-else></el-image>
    </el-dialog>
    <cedForm ref="cedForm" @callback="callback"></cedForm>
  </div>
</template>

<script>
import GridLayout from "@/views/components/layout/grid-layout.vue";
import pdf from "@/components/pdf/pdf.vue";
import cedForm from "./personnelLicenceCedForm.vue";

import { mapGetters } from "vuex";

import {
  employeesLineList,
  employeesLineRemove,
} from "@/api/responsibility/licenceEmployees";
import * as API from "@/api/personnelHolography";
import { downloadFileBlob } from "@/util/util";
export default {
  components: {
    GridLayout,
    pdf,
    cedForm,
  },
  data() {
    return {
      tableData: [],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      query: {},
      userId: "",
      fileUrl: "",
      imageDiavlog: false,
      opType: "",
    };
  },
  computed: {
    ...mapGetters(["permission", "colorName"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.eduquestions_add, true),
        viewBtn: this.vaildData(this.permission.eduquestions_view, true),
        editBtn: this.vaildData(this.permission.eduquestions_edit, true),
        delBtn: this.vaildData(this.permission.eduquestions_delete, true),
        downloadBtn: this.vaildData(
          this.permission.eduquestions_download,
          true
        ),
        settingBtn: this.vaildData(this.permission.eduquestions_setting, true),
      };
    },
    searchColumns() {
      return [
        {
          prop: "fileName",
          placeholder: "证件名称",
          span: 4,
        },
      ];
    },
    tableOptions() {
      return {
        menu: this.opType == "edit",
        selection: false,
        column: [
          {
            label: "证件名称",
            prop: "fileName",
            align: "center",
            slot: true,
            overHidden: true,
          },
          {
            label: "证件类型",
            prop: "cardType",
            align: "center",
            type: "cascader",
            dicUrl: `/api/sinoma-system/dict-biz/dictionary-tree?code=certificate_type`,
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            overHidden: true,
          },
          {
            label: "证件状态",
            prop: "certificateState",
            align: "center",
            overHidden: true,
            type: "select",
            dicUrl:
              "/api/sinoma-system/dict-biz/dictionary?code=certificate_state",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            overHidden: true,
          },
          {
            label: "注册地",
            prop: "regoLocal",
            align: "center",
            overHidden: true,
            type: "select",
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=rego_local",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            overHidden: true,
          },
          {
            label: "发证日期",
            prop: "startDate",
            align: "center",
          },
          {
            label: "到证日期",
            prop: "endDate",
            align: "center",
          },
        ],
      };
    },
    headBtnOptions() {
      let buttonBtn = [];
      if (this.opType == "edit") {
        buttonBtn.push({
          label: "上传",
          emit: "head-upload",
          type: "button",
          icon: "",
          btnOptType: "upload",
        });
      }
      return buttonBtn;
    },
    gridRowBtn(row) {
      let buttonBtn = [];
      if (this.permissionList.downloadBtn) {
        buttonBtn.push({
          label: "下载",
          emit: "row-download",
          type: "button",
          icon: "",
        });
      }
      if (this.permissionList.editBtn) {
        buttonBtn.push({
          label: this.$t(`cip.cmn.btn.editBtn`),
          emit: "row-edit",
          type: "button",
          icon: "",
        });
      }
      if (this.permissionList.delBtn) {
        buttonBtn.push({
          label: this.$t(`cip.cmn.btn.delBtn`),
          emit: "row-remove",
          type: "button",
          icon: "",
        });
      }
      return buttonBtn;
    },
  },
  methods: {
    init(id, pageType, opType) {
      this.opType = opType;
      this.userId = id;
      this.onLoad(this.page);
    },
    /** 右侧表格操作 */
    onLoad(page, params = {}) {
      this.tableLoading = true;
      this.page = page;
      params.userId = this.userId;
      // this.query.userId = this.userId
      API.personelLicencePage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      ).then((res) => {
        const data = res.data.data;
        this.$refs.gridLayOut.page.total = data.total;
        // this.tableData = data.records;
        this.tableData = data.records ? data.records.map(val=>{
                    val.holdMode = val.holdMode.toString()
                    return val
                }) : [];
        this.tableLoading = false;
      });
      // employeesLineList(params).then((res) => {
      //   if (res.data.code == 200) {
      //     console.log("res", res.data);
      //     this.tableData = res.data.data;
      //     this.tableLoading = false;
      //   }
      // });
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage;
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm);
    },
    //size改变
    handleSizeChange(e) {
      this.page.pageSize = e.pageSize;
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm);
    },
    // 搜索
    gridHeadSearch(query) {
      const data = this.query;
      this.page.currentPage = 1;
      this.query = { ...data, ...query };
      this.onLoad(this.page);
    },
    // 重置
    gridHeadEmpty(query) {
      this.page.currentPage = 1;
      const data = this.query;
      this.query = { ...data, ...query };
      this.onLoad(this.page);
    },
    rowView(row) {
      let pos = row.files.lastIndexOf(".");
      const type = row.files.slice(pos + 1, row.files.length);
      this.fileUrl = { src: row.files, name: row.fileName, type };
      this.imageDiavlog = true;
    },
    getFileName(name) {
      let pos = name.lastIndexOf(".");
      return name.slice(0, pos);
    },
    handleUpload() {
      this.$refs.cedForm.init(
        {
          userId: this.userId,
        },
        "add"
      );
    },
    callback() {
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    rowEdit(row, index) {
      row.userId = this.userId;
      this.$refs.cedForm.init(row, "edit");
    },
    rowRemove(row, index) {
      this.$confirm("确定删除当前数据?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        employeesLineRemove(row.id).then(() => {
          this.$message.success("操作成功！");
          this.callback();
        });
      });
    },
    rowDownload(row) {
      downloadFileBlob(row.files, row.fileName);
    },
  },
};
</script>
<style scoped lang="scss"></style>
