var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible, title: _vm.title },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "avue-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "avueForm",
              attrs: { option: _vm.formOption },
              on: { submit: _vm.handleSubmit },
              model: {
                value: _vm.dataForm,
                callback: function ($$v) {
                  _vm.dataForm = $$v
                },
                expression: "dataForm",
              },
            },
            [
              _c(
                "template",
                { slot: "cardType" },
                [
                  _c("el-cascader", {
                    ref: "cardType",
                    staticStyle: { width: "100%" },
                    attrs: {
                      placeholder: "请选择证件类型",
                      disabled: _vm.type == "view",
                      options: _vm.typeList,
                      props: { label: "dictValue", value: "dictKey" },
                    },
                    on: { change: _vm.handleChangeType },
                    model: {
                      value: _vm.dataForm.cardType,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataForm, "cardType", $$v)
                      },
                      expression: "dataForm.cardType",
                    },
                  }),
                ],
                1
              ),
              _c(
                "template",
                { slot: "fileName" },
                [
                  _c(
                    "el-input",
                    {
                      ref: "fileName",
                      on: { focus: _vm.uploadFile },
                      model: {
                        value: _vm.fileName,
                        callback: function ($$v) {
                          _vm.fileName = $$v
                        },
                        expression: "fileName",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { slot: "append", disabled: _vm.ocrBtn },
                          on: { click: _vm.handleOcr },
                          slot: "append",
                        },
                        [_vm._v("智能识别")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", loading: _vm.dialogLoading },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t(`cip.cmn.btn.celBtn`)) + " ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    type: "primary",
                    loading: _vm.dialogLoading,
                  },
                  on: { click: _vm.handleSave },
                },
                [_vm._v(" " + _vm._s(_vm.$t(`cip.cmn.btn.defBtn`)) + " ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-upload",
        {
          ref: "fileUpload",
          staticClass: "upload-demo",
          attrs: {
            action:
              "api/sinoma-resource/oss/endpoint/put-file-attach-name-file",
            headers: _vm.headers,
            file: "",
            limit: 1,
            "show-file-list": false,
            "on-success": _vm.handleUploadSuccess,
            accept: "image/*,.pdf",
          },
        },
        [
          _c("el-button", {
            ref: "upload",
            staticStyle: { display: "none" },
            attrs: { size: "small" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }